import { Button, Flex } from '@chakra-ui/react';
import { FC } from 'react';

import FemaleSVG from '~/assets/icons/female.svg';
import MaleSVG from '~/assets/icons/male.svg';
import { useAppLead } from '~/hooks/useAppLead';
import { useAppRouter } from '~/hooks/useAppRouter';
import { useTracking } from '~/hooks/useTracking';

import { TransText } from './TransText';

export const GenderSelection: FC<{ flowName?: string }> = ({ flowName: currentFlowName }) => {
  const { updateQuizAnswers } = useAppLead();
  const { quizQuestionAnswer } = useTracking();
  const { pushNextRoute, flowName, query } = useAppRouter('/quiz');

  const handleSelection = (gender: 'female' | 'male') => {
    updateQuizAnswers({ gender });

    quizQuestionAnswer({ category: '01_gender', label: gender });
    const passQueryParams = { ...query } as Record<string, string>;
    delete passQueryParams.catchall;

    pushNextRoute(
      flowName
        ? { ...passQueryParams, flowName }
        : currentFlowName
          ? { ...passQueryParams, flowName: currentFlowName }
          : passQueryParams
            ? passQueryParams
            : undefined,
    );
  };

  return (
    <Flex gap={{ base: '0.5rem', md: '1rem' }} width='100%'>
      <Button
        display='inline-block'
        variant='secondary'
        flex={1}
        gap='1rem'
        onClick={() => handleSelection('male')}
        textTransform='uppercase'
        whiteSpace='normal'
      >
        <TransText transKey='common:weight_loss_for_men'></TransText>
      </Button>
      <Button
        display='inline-block'
        flex={1}
        gap='1rem'
        onClick={() => handleSelection('female')}
        textTransform='uppercase'
        whiteSpace='normal'
      >
        <TransText transKey='common:weight_loss_for_women'></TransText>
      </Button>
    </Flex>
  );
};

export const GenderSelectionWithIcons = () => {
  const { updateQuizAnswers } = useAppLead();
  const { quizQuestionAnswer } = useTracking();
  const { pushNextRoute } = useAppRouter('/quiz');

  const handleSelection = (gender: 'female' | 'male') => {
    updateQuizAnswers({ gender });

    quizQuestionAnswer({ category: '01_gender', label: gender });

    pushNextRoute();
  };

  return (
    <Flex gap={{ base: '0.5rem', md: '1rem' }} width='100%'>
      <Button
        display='flex'
        flex={1}
        gap='1rem'
        onClick={() => handleSelection('male')}
        textTransform='uppercase'
        whiteSpace='normal'
        bg='black'
        color='white'
        borderRadius='2rem'
        _hover={{
          bg: 'black',
        }}
      >
        <MaleSVG />
        <TransText transKey='Male'></TransText>
      </Button>
      <Button
        display='flex'
        flex={1}
        gap='1rem'
        onClick={() => handleSelection('female')}
        textTransform='uppercase'
        bg='#F36404'
        color='white'
        borderRadius='2rem'
        _hover={{
          bg: '#F36404',
        }}
      >
        <FemaleSVG />
        <TransText transKey='Female'></TransText>
      </Button>
    </Flex>
  );
};
